<template>
  <Swiper :space-between="16" class="w-full" @slide-change="onSlideChange">
    <SwiperSlide v-for="(step, index) in steps" :key="`step-${index}`">
      <div>
        <ImagesPersonOverlay :src="step.imageUrl" />
        <div class="onboarding__body">
          <h2 class="onboarding__title">{{ step.title }}</h2>
          <p>
            {{ step.text }}
          </p>
        </div>
      </div>
    </SwiperSlide>
  </Swiper>

  <div class="onboarding__progress">
    <div class="ruler">
      <div class="ruler__background" :style="{ transform: `translateX(${rulerProgress})` }"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface OnboardingStep {
  title: string;
  text: string;
  imageUrl: string;
}

const activeIndex = ref(0);

const rulerProgress = computed(() => {
  switch (activeIndex.value) {
    case 0:
      return '-90%';
    case 1:
      return '-47%';
    case 2:
      return 0;
  }
  return 0;
});

function onSlideChange(e: any) {
  activeIndex.value = e.activeIndex;
}

const steps: OnboardingStep[] = [
  {
    title: 'Welkom bij Groenhart!',
    text: 'Bij ons kun je terecht voor al je gereedschap, ijzerwaren en bouwmateriaal. Hulp nodig? Dan staan we voor je klaar met persoonlijk advies.',
    imageUrl: '/images/mark.png',
  },
  {
    title: 'Makkelijk & Snel',
    text: 'Gebruik jouw camera om producten te scannen en deze opnieuw te bestellen en/of aan jouw bestellijst toe te voegen.',
    imageUrl: '/images/astrid.png',
  },
  {
    title: 'Log in op Mijn Groenhart',
    text: 'Gebruik je Groenhart-account om in te loggen. Jouw prijzen, bestellijsten en acties staan dan handig bij elkaar in de app.',
    imageUrl: '/images/jan-pieter.png',
  },
];
</script>

<style lang="scss" scoped>
.onboarding {
  &__title {
    margin-top: px(24);
    margin-bottom: px(16);
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-top: px(22);
    margin-bottom: px(12);
  }

  &__body {
    text-align: center;
    margin-bottom: px(32);
  }

  &__progress {
    display: flex;
    justify-content: center;
    margin-bottom: px(16);

    .ruler {
      mask-image: url('/images/ruler.svg');
      mask-repeat: no-repeat;
      background-color: $c-neutral-3;

      &__background {
        width: px(139);
        height: px(40);
        background-color: $c-green-3;
        transition: transform 0.3s linear;
      }
    }
  }
}
</style>
